import Footer from "./Footer";
import NavBar from "./NavBar";
import Logo from "./logo";
import '../css/About.css';


const About = () => {
    return (
        <>
            <div className="about">
                <div className="header">
                    <Logo />
                    <NavBar />
                </div>
                <div className="main">

                    <article>
                        <img src="" alt="" />
                        <p>
                            En Actecom, nos enorgullece ser una empresa destacada en el sector de las telecomunicaciones, especializada en la instalación y mantenimiento de centralitas telefónicas. Fundada en 2011, contamos con un equipo de técnicos altamente capacitados y con más de 30 años de experiencia.<br /><br />

                            Nuestro principal objetivo es brindar soluciones de comunicación confiables y eficientes a nuestros clientes. Nos esforzamos por mantenernos a la vanguardia de las tecnologías actuales, asegurándonos de que nuestros empleados estén actualizados con las últimas tendencias y avances del sector.<br /><br />

                            En Actecom, comprendemos la importancia de una comunicación fluida y efectiva para las empresas en un mundo cada vez más interconectado. Trabajamos en estrecha colaboración con nuestros clientes para entender sus necesidades y proporcionarles soluciones personalizadas que impulsen su eficiencia y productividad.<br /><br />

                            Nuestro compromiso con la excelencia nos ha permitido establecer relaciones duraderas con nuestros clientes, quienes confían en nosotros para la instalación y el mantenimiento de sus sistemas de telecomunicaciones. Nos enorgullece ofrecer un servicio profesional, ágil y orientado al cliente, respaldado por nuestra sólida experiencia y conocimiento técnico.<br /><br />

                            En Actecom, estamos listos para enfrentar los desafíos del mundo de las telecomunicaciones y ayudar a las empresas a adaptarse y prosperar en un entorno empresarial en constante evolución. <br /><br />
                            Contáctenos hoy mismo y descubra cómo podemos ayudarlo a optimizar sus comunicaciones y mantenerse actualizado con las últimas tecnologías
                        </p>

                    </article>
                </div>

            </div >
            <Footer />

        </>

    )
}
export default About;