import '../css/logo.css';
import logo from '../assets/images/actecomLogo.png';

const Logo = () => {

  return (

    <div className='logo'>
      <img src={logo} alt="Logo de Actecom" />
    </div>
  )
}

export default Logo;