import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../css/NavBar.css';

const NavBar = () => {
    const [isActive, setIsActive] = useState(false);

    const handleMenuToggle = () => {
        setIsActive(!isActive);
    };

    const location = useLocation();

    return (
        <div className={`Navbar ${isActive ? 'active' : ''}`}>
            <div className='links'>
                <a className={location.pathname === '/Home' || location.pathname === '/' ? 'activo' : ''} href="/Home">Home</a>
                <a className={location.pathname === '/Services' ? 'activo' : ''} href="/Services">Servicios</a>
                {/*                 <a className={location.pathname === '/Products' ? 'activo' : ''} href="/Products">Productos</a>
 */}                <a className={location.pathname === '/About' ? 'activo' : ''} href="/About">About us</a>
            </div>

            <div className="contacta">
                <a href="tel:937425045" className="tel">LLAMA AL 937425045</a>
            </div>


            <div className="hamburger" onClick={handleMenuToggle}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
        </div>
    );
}

export default NavBar;
