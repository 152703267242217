import Footer from "./Footer";
import NavBar from "./NavBar";
import Logo from "./logo";
import office from '../assets/images/oficina.jpg';

const Home = () => {
    return (
        <>
            <div className="home">
                <div className="header">
                    <Logo />
                    <NavBar />
                </div>
                <div className="hero">
                    <img src={office} alt='Chica trabajando en oficina' />
                    <article>
                        <h1>DIGITALÍZATE</h1>
                        <p>
                            En un mundo cada vez más interconectado y digital, es esencial contar con un sistema de comunicación confiable y eficiente para mantenerse al día con las demandas del mercado actual.
                            <br />
                            <br />
                            Es aquí donde entramos en juego, ofreciendo soluciones de telefonía IP de vanguardia para satisfacer todas las necesidades de comunicación de su empresa.
                            <br />
                            <br />
                            Esto se traduce en una serie de beneficios significativos para su empresa:
                        </p>
                        <ul>
                            <li>AHORRO DE COSTOS</li>
                            <li>FLEXIBILIDAD Y MOVILIDAD</li>
                            <li>FUNCIONALIDADES AVANZADAS</li>
                            <li>ESCALABILIDAD Y CRECIMIENTO</li>
                            <li>INTEGRACIÓN EMPRESARIAL</li>
                        </ul>
                    </article>

                </div>

            </div>
            <Footer />

        </>

    )
}
export default Home;