import Footer from "./Footer";
import NavBar from "./NavBar";
import Logo from "./logo";
import Card from './Card.jsx';
import '../css/Services.css';
import mantenimiento from '../assets/images/mantenimiento2.jpg';
import instalador from '../assets/images/instalador.jpeg';
import factura from '../assets/images/factura.jpg';


const Services = () => {
    return (
        <>
            <div className="services">
                <div className="header">
                    <Logo />
                    <NavBar />
                </div>
                <div className="main">
                    <article className="card">
                        <h3>MANTENIMIENTO</h3>
                        <div className="cardBody">
                            <img src={mantenimiento} alt="" />
                            <p>El mantenimiento de centralitas telefónicas contribuye a garantizar la continuidad de las comunicaciones en una empresa, minimizando los tiempos de inactividad y maximizando la productividad.<br /><br />
                                Además, ayuda a prevenir posibles problemas antes de que se conviertan en interrupciones graves del servicio.<br /><br />
                                Éste servicio es esencial para asegurar la fiabilidad y el rendimiento óptimo de los sistemas de comunicación. Proporciona supervisión, actualizaciones, resolución de problemas, configuración, capacitación y soporte técnico para garantizar un funcionamiento fluido y eficiente de las comunicaciones telefónicas.<br /><br />
                                Contrate ahora su mantenimiento a un buen precio.
                            </p>
                        </div>
                    </article>
                    <article className="card">
                        <h3>INSTALACIONES</h3>
                        <div className="cardBody">
                            <img src={instalador} alt="" />
                            <p>Actecom le ofrece soluciones completas para mejorar la comunicación de su negocio. Nuestro equipo experto se encarga de la instalación y configuración personalizada de las centralitas. <br /><br />Ofrecemos funcionalidades avanzadas, como gestión de llamadas, IVR, grabación de llamadas y brindamos soporte técnico confiable. <br /><br />No importa el tamaño de su empresa, nuestras soluciones le ayudarán a mejorar la comunicación interna y externa, proporcionando un servicio excepcional a sus clientes y colaboradores. <br /><br />¡Contáctanos hoy y descubre cómo nuestras centralitas telefónicas pueden potenciar la comunicación de su negocio!
                            </p>
                        </div>
                    </article>
                    <article className="card">
                        <h3>REVISIÓN DE FACTURAS</h3>
                        <div className="cardBody">
                            <img src={factura} alt="" />
                            <p>
                                En Actecom, ofrecemos un servicio de revisión de facturas telefónicas sin compromiso para ayudarte a reducir sus costos mensuales.<br /> <br />Nuestro equipo analizará sus facturas y le proporcionará recomendaciones personalizadas para optimizar sus gastos de comunicación. <br /><br />No estás obligado a tomar ninguna acción, pero te ofrecemos la oportunidad de ahorrar sin comprometer la calidad del servicio.<br /><br /> Contáctanos hoy mismo y descubre cómo podemos ayudarte a reducir sus costos telefónicos sin esfuerzo.
                            </p>
                        </div>
                    </article>

                    {/* <Card
                        title='REVISIÓN DE FACTURAS'
                        src={factura}
                        text='Esto es un texto de pruebaLorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de relleno en documentos electrónicos, quedando esencialmente igual al original. Fue popularizado en los 60s con la creación de las hojas "Letraset", las cuales contenian pasajes de Lorem Ipsum, y más recientemente con software de autoedición, como por ejemplo Aldus PageMaker, el cual incluye versiones de Lorem Ipsum.' /> */}
                </div>

            </div>
            <Footer />

        </>

    )
}
export default Services;