import '../css/Footer.css';
import Logo from './logo'

const Footer = () => {

    return (
        <div className="footer">
            <address>
                <div className="mail">
                    <span>Actecom -</span>  <a href="mailto:actecom@actecom.net">actecom@actecom.net</a>
                </div>
                <div className="location">
                    <span>Lliçà de Vall - Barcelona</span>
                </div>
                <div className="tel">
                    <a href="tel:+3493742504">+34 937 425 045 / </a>
                    <a href="tel:+34660407549">+34 660 407 549</a>
                </div>
            </address>
        </div>

    );
}

export default Footer;